import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入element-ui框架
import ElementUI from "element-ui";
ElementUI.Dialog.props.lockScroll.default = false;
import "element-ui/lib/theme-chalk/index.css"

//引入axios插件
import axios from "axios";
Vue.prototype.$axios = axios;

// 使用echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

//拷贝  复制
import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

//引入moment插件
import moment from 'moment' //导入文件
Vue.prototype.$moment = moment; //赋值使用

import Bus from './bus';
Vue.prototype.$bus = Bus

Vue.use(ElementUI);



//引入css重置样式表
import "./assets/css/reset.css"

Vue.config.productionTip = false;

new Vue({
    axios,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");