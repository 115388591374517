
import local from "./local"

export function getRoleKey(name){
    if(local.get("powersIdsAndChild")){
        let powersIdsAndChildArr= local.get("powersIdsAndChild").split(",");
        if (powersIdsAndChildArr.indexOf(name)=='-1'){
            return false
        }else{
            return true
        }
    }else{
        return false
    }
 
}