<template>
  <div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-form :model="pwdData" label-position="right" label-width="90px">
        <el-form-item label="新密码">
          <el-input
          size="small"
            label="请输入新密码"
            type="password"
            v-model.trim="pwdData.newPassWord"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input
          size="small"
            label="请再次输入新密码"
            type="password"
            v-model.trim="pwdData.newPassWordtwo"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { setPassWord_api } from "../apis/head.js";
import local from "@/utils/local.js";
export default {
  data() {
    return {
      pwdData: {
        newPassWord: "",
        newPassWordtwo: "",
      },
      dialogFormVisible: false,
    };
  },
  methods: {
    close() {
      this.dialogFormVisible = false;
    },
    open(){
          this.pwdData= {
        newPassWord: "",
        newPassWordtwo: "",
      }
      this.dialogFormVisible=true
    },
    onSubmit() {
      if (this.pwdData.newPassWord != this.pwdData.newPassWordtwo) {
        this.$message.warning("你两次输入的密码不一致");
      } else if (
        this.pwdData.newPassWord == "" ||
        this.pwdData.newPassWord == null
      ) {
        this.$message.warning("你输入的新密码不符合规则，请重新输入");
      } else {
        this.editPwdFn();
      }
    },
    async editPwdFn() {
      let { code, data } = await setPassWord_api({
        newPassWord: this.pwdData.newPassWord,
      });
      if (code == 0) {
        this.$message.success("修改密码成功，即将跳转登录页面");
        setTimeout(() => {
          local.remove("tk");
          local.remove("releaseScheduling");
          local.remove("role");
          local.remove("userName");
          local.remove("powersIdsAndChild");
          local.remove("hotelId");

          //local.clear();
          this.$router.push("/login");
        }, 2000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>>

