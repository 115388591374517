// 所有账号的api

import req from "../utils/req"

//登录接口
export function login_api(params) {
    return req.get("/otaHotel/manager/login", params)
}
//分页查询用户
export function accList_api(params) {
    return req.get("/otaHotel/role/getlist", params)
}
//分页查询人员
export function person_list(params) {
    return req.get("/otaHotel/manager/getlistwithrole", params)
}
//分页查询群组
export function group_list(params) {
    return req.get("/otaHotel/horseGroup/getlist", params)
}
//分页查询马甲管理
export function vest_list(params) {
    return req.get("/otaHotel/horseVest/getlist", params)
}
//添加角色
export function add_Role(params) {
    return req.post("/otaHotel/role/creat", params)
}
//删除角色
export function del_role(params) {
    return req.get("/otaHotel/role/deleteByIds", params)
}
//删除用户
export function delPerson_api(params) {
    return req.get("/otaHotel/manager/deleteByIds", params)
}
//删除马甲
export function delVest_api(params) {
    return req.get("/otaHotel/horseVest/deleteByIds", params)
}
//删除群组
export function delGroup_api(params) {
    return req.get("/otaHotel/horseGroup/deleteByIds", params)
}

// 查询马甲表分组匹配 (账号管理>马甲管理ak)
export function getNotVestlist_apiak(params){
    return req.get('/otaHotel/horseGroup/getNotVestlist',params)
}

// 设置组的五大平台马甲绑定 (账号管理>马甲管理ak) 改用原生发送ajax请求
export function setPlatformIdMap_apiak(params){
    return req.post('/otaHotel/horseGroupMap/setPlatformIdMap',params)
}

// 根据角色key获取关联分组 分组数据 (账号管理>人员管理ak)
export function getGroupList_apiak(params){
    return req.get('/otaHotel/horseGroup/getGroupList',params)
}

// 根据角色key设置关联分组 分组数据 (账号管理>人员管理ak)
export function setHorseGroupManagerId_apiak(params){
    return req.get('/otaHotel/horseGroup/setHorseGroupManagerId',params)
}

// 根据id查询账号和角色 (账号管理>人员管理ak)
export function getRolesList_apiak(params){
    return req.get('/otaHotel/role/getlist',params)
}
// roleid获取权限树
export function getPowerTree_api(params){
    return req.get('/otaHotel/power/getPowerTree',params)
}
// roleidset权限树
export function setPowerTree_api(params){
    return req.postlg('/otaHotel/power/setPowerTree',params)
}

//添加人员
export function creatPerson(params){
    return req.postlg('/otaHotel/manager/creat',params)
}
//添加人员
export function getHotelNum_api(params){
    return req.get('/otaHotel/manager/setHotelNum',params)
}









