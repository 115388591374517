<template>
  <div class="navs">
    <el-menu
      router
      :default-active="this.$route.path"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#102647"
      text-color="#ccc"
      active-text-color="#fff"
    >
      <el-menu-item index="/workbench" v-if="gongzuotai">工作台</el-menu-item>
      <el-menu-item index="/order" v-if="roleOrder">订单管理</el-menu-item>
      <el-menu-item index="/hotel/hotelList" v-if="roleHotel">酒店管理</el-menu-item>
      <el-menu-item index="/room" v-if="roleRoom">房型管理</el-menu-item>
      <el-menu-item index="/service" v-if="roleService">服务中心</el-menu-item>
      <el-menu-item index="/finance" v-if="roleFinance">财务管理</el-menu-item>
      <el-menu-item index="/hotelPackage" v-if="roleHotelPackage">酒店套餐</el-menu-item>
      <el-menu-item index="/account" v-if="roleAccount">账号管理</el-menu-item>
      <el-menu-item index="/customerService" v-if="roleCustomerService">客服管理</el-menu-item>
      <el-menu-item index="/basics" v-if="roleBasics">基础设置</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {getRoleKey} from "../utils/rolekey"
export default {
  data() {
    return {
      gongzuotai:getRoleKey('role_workbench'),
      roleOrder:getRoleKey('role_order'),
      roleHotel:getRoleKey('role_hotel'),
      roleRoom:getRoleKey('role_room'),
      roleService:getRoleKey('role_service'),
      roleFinance:getRoleKey('role_finance'),
      roleHotelPackage:getRoleKey('role_hotelPackage'),
    
      roleAccount:getRoleKey('role_account'),
      roleCustomerService:getRoleKey('role_customerService'),
      roleBasics:getRoleKey('role_basics'),
    };
  },
  mounted(){
    console.log(this.gongzuotai)
  }
};
</script>

<style lang="less" scoped>
.navs {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /deep/.el-menu {
    display: flex;
    justify-content: space-between;
  // justify-content: space-around;
    height: 100%;
  }
}
</style>