import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "../views/Layout"
import Login from "../views/Login"
Vue.use(VueRouter);

// import local from "../utils/local"

export const asyncRoutes = [
  //登陆组件
  {
    path: '/',
    redirect: '/login' //路由重定向
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  //工作台
  {
    path: '',
    component: Layout,
    name: 'Workbench',
    children: [{
      path: '/workbench',
      meta: {
        title: "后台管理"
      },
      component: () => import("../views/workbench/Workbench")
    }]
  },
  //订单管理
  {
    path: '',
    component: Layout,
    name: 'Order',
    children: [{
      path: '/order',
      meta: {
        title: "订单管理"
      },
      component: () => import("../views/order/Order")
    },
    {
      path: '/orderdetailsinfo',
      meta: {
        title: "订单详情"
      },
      component: () => import("../views/order/OrderDetailsInfo")
    },
    {
      path: '/manualorder',
      meta: {
        title: "手工单录入"
      },
      component: () => import("../views/order/ManualOrder")
    },
    {
      path: '/releaseOrder',
      meta: {
        title: "发布订单"
      },
      component: () => import("../views/order/ReleaseOrder")
    },
   ]
  },
  //酒店管理
  {
    path: '/hotel',
    meta: {
      title: '酒店管理'
    },
    component: Layout,
    redirect: '/hotel/hotelList',
    children: [{
        path: '/hotel/hotelList',
        meta: {
          title: '酒店管理列表'
        },
        component: () => import("../views/hotel/Hotel")
      },
      {
        path: '/hotel/addhotel',
        name:'Hotel',
        meta: {
          title: '新增酒店'
        },
        component: () => import("../views/hotel/Addhotel")
      }
    ]
  },
  // {
  //   path: '',
  //   component: Layout,
  //   name: 'Hotel',
  //   children: [{
  //     path: '/hotel',
  //     meta: {
  //       title: "酒店管理"
  //     },
  //     component: () => import("../views/hotel/Hotel")
  //   }]
  // },
  // //新增酒店
  // {
  //   path: '',
  //   component: Layout,
  //   name: 'Addhotel',
  //   children: [{
  //     path: '/addhotel',
  //     meta: {
  //       title: "新增酒店"
  //     },
  //     component: () => import("../views/hotel/Addhotel")
  //   }]
  // },
  //房型管理
  {
    path: '',
    component: Layout,
    name: 'Room',
    children: [
      {
      path: '/room',
      meta: {
        title: "房型管理"
      },
      component: () => import("../views/room/Room")
    },
      {
      path: '/roomloglist',
      meta: {
        title: "房型日志"
      },
      component: () => import("../views/room/RoomLogList")
    },
  ]
  },
  //服务中心
  {
    path: '',
    component: Layout,
    name: 'Service',
    children: [{
      path: '/service',
      meta: {
        title: "服务中心"
      },
      component: () => import("../views/service/Service")
    }]
  },
  //财务管理
  {
    path: '',
    component: Layout,
    name: 'Finance',
    children: [{
      path: '/finance',
      meta: {
        title: "财务管理"
      },
      component: () => import("../views/finance/Finance")
    }]
  },
  //酒店套餐
  {
    path: '',
    component: Layout,
    name: 'HotelPackage',
    children: [
      {
      path: '/hotelPackage',
      meta: {
        title: "酒店套餐"
      },
      component: () => import("../views/hotelPackage/HotelPackage"),

      children:[
        {
          path: '/',
          redirect: '/promotionManagement',
        },
        {
          path: '/promotionManagement',
          name: 'PromotionManagement',
          meta: {
            title: "促销管理"
          },
          component: () => import("../views/hotelPackage/PromotionManagement")
        },
        {
          path: '/enrollment',
          name: 'Enrollment',
          meta: {
            title: "活动报名"
          },
          component: () => import("../views/hotelPackage/Enrollment")
        },
        {
          path: '/rightsCenter',
          name: 'RightsCenter',
          meta: {
            title: "权益中心"
          },
          component: () => import("../views/hotelPackage/RightsCenter")
        },
       
        {
          path: '/addPromotion',
          meta: {
            title: "新建促销"
          },
          component: () => import("../views/hotelPackage/AddPromotion")
        },
        {
          path: '/addMidnightPromote',
          meta: {
            title: "新建午夜特惠"
          },
          component: () => import("../views/hotelPackage/AddMidnightPromote")
        },
        {
          path: '/addTonightPromote',
          meta: {
            title: "新建今夜甩卖"
          },
          component: () => import("../views/hotelPackage/AddTonightPromote")
        },
        {
          path: '/addTravelPromote',
          meta: {
            title: "新建出行特惠"
          },
          component: () => import("../views/hotelPackage/AddTravelPromote")
        },
        {
          path: '/addNewGuestPromote',
          meta: {
            title: "新建门店新客"
          },
          component: () => import("../views/hotelPackage/AddNewGuestPromote")
        },
        {
          path: '/addSagawaPromote',
          meta: {
            title: "新建天天特价"
          },
          component: () => import("../views/hotelPackage/AddSagawaPromote")
        },
        {
          path: '/addContinuousPromote',
          meta: {
            title: "新建连住特惠"
          },
          component: () => import("../views/hotelPackage/AddContinuousPromote")
        },
        {
          path: '/addAdvanceReservePromote',
          meta: {
            title: "新建提前预定"
          },
          component: () => import("../views/hotelPackage/AddAdvanceReservePromote")
        },
        {
          path: '/addFlashSalePromote',
          meta: {
            title: "新建限时抢购"
          },
          component: () => import("../views/hotelPackage/AddFlashSalePromote")
        },
        {
          path: '/optimalWill',
          name: 'OptimalWill',
          meta: {
            title: "优享会"
          },
          component: () => import("../views/hotelPackage/OptimalWill")
        },
        {
          path: '/addOptimalWillPage',
          name: 'AddOptimalWillPage',
          meta: {
            title: "优享会"
          },
          component: () => import("../views/hotelPackage/AddOptimalWillPage")
        },
        {
          path: '/promotionManagementYiLong',
          name: 'PromotionManagementYiLong',
          meta: {
            title: "艺龙促销管理"
          },
          component: () => import("../views/hotelPackage/PromotionManagementYiLong")
        },
        {
          path: '/optimalWillYiLong',
          name: 'OptimalWillYiLong',
          meta: {
            title: "艺龙优享会"
          },
          component: () => import("../views/hotelPackage/OptimalWillYiLong")
        },
       
        {
          path: '/stencilYiLong',
          name: 'StencilYiLong',
          meta: {
            title: "艺龙模板"
          },
          component: () => import("../views/hotelPackage/StencilYiLong")
        },
        {
          path: '/addPromotionYiLong',
          name: 'AddPromotionYiLong',
          meta: {
            title: "新建促销"
          },
          component: () => import("../views/hotelPackage/AddPromotionYiLong")
        },
        {
          path: '/addOptimalWillYiLongPage',
          name: 'AddOptimalWillYiLongPage',
          meta: {
            title: "新建艺龙优享会"
          },
          component: () => import("../views/hotelPackage/AddOptimalWillYiLongPage")
        },
        
      ],
    },
   
  ]
  },
  //账号管理
  {
    path: '',
    component: Layout,
    name: 'Account',
    children: [{
      path: '/account',
      meta: {
        title: "账号管理"
      },
      component: () => import("../views/account/Account")
    }]
  },
  //客服管理
  {
    path: '',
    component: Layout,
    name: 'CustomerService',
    children: [{
      path: '/customerService',
      meta: {
        title: "客服管理"
      },
      component: () => import("../views/customerService/CustomerService")
    }]
  },
  //基础设置
  {
    path: '',
    component: Layout,
    name: 'Basics',
    children: [{
      path: '/basics',
      meta: {
        title: "基础设置"
      },
      component: () => import("../views/basics/Basics")
    }]
  },
  //猩房房手机端发单
  {
    path: '/mobilereleaseOrder',
    name: 'mobilereleaseOrder',
    component: () => import("../views/MobileReleaseOrder")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: asyncRoutes
});


export default router;