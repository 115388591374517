<template>
  <div class="login">
    <!-- 登录信息 -->
    <div class="user">
      <div class="head">
        <img src="../assets/imgs/矢量智能对象@2x.png" alt="加载失败" />
        <p>成都嘉瑜信息技术有限公司</p>
      </div>
      <el-form ref="form" :model="form" status-icon :rules="rules">
        <!-- 用户名 -->
        <el-form-item prop="name" class="acc">
          <el-input
            size="small"
            placeholder="请输入账号"
            v-model="form.name"
            prefix-icon="iconfont icon-wo"
          ></el-input>
          <img src="../assets/imgs/我的.png" alt="加载失败" />
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="pwd" class="pwd">
          <el-input
            size="small"
            placeholder="请输入密码"
            v-model="form.pwd"
            :type="isshow ? 'password' : 'text'"
            @click.native="display"
            :suffix-icon="
              isshow ? 'iconfont icon-yanjing_bi' : 'iconfont icon-yanjing_kai'
            "
          ></el-input>
          <img src="../assets/imgs/密码.png" alt="加载失败" />
        </el-form-item>
        <el-form-item>
          <!-- <div style="display: flex"> -->
          <div v-if="isMobile">
            <el-input
              ref="inputCode"
              size="small"
              style="width: 175px"
              placeholder="请输入验证码"
              v-model="inputCode"
            ></el-input>
            <span @click="createCode" id="spancode">
              <SIdentify :identifyCode="code"></SIdentify>
            </span>
          </div>
          <div v-else style="display: flex">
            <el-input
              ref="inputCode"
              size="small"
              style="width: 175px"
              placeholder="请输入验证码"
              v-model="inputCode"
            ></el-input>
            <span @click="createCode" id="spancode">
              <SIdentify :identifyCode="code"></SIdentify>
            </span>
          </div>
        </el-form-item>

        <el-checkbox v-model="checked" @change="change"
          >一个月内自动登录</el-checkbox
        >
        <!-- 登录 -->
        <el-form-item>
          <div>
            <el-button
              size="medium"
              type="primary"
              @click="onSubmit"
              class="btn"
              >登录</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
//引入登录接口
import { login_api } from "@/apis/acc";
//引入local
import local from "@/utils/local.js";
import SIdentify from "../components/SIdentify.vue";
export default {
  components: {
    SIdentify,
  },
  data() {
    return {
      // 表单
      form: {
        name: "",
        pwd: "",
      },
      //输入框是否有值
      isshow: true,
      //表单验证
      rules: {
        //验证用户名
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 16,
            message: "长度在 2 到 16 个字符",
            trigger: "blur",
          },
        ],
        pwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
      //自动登录选项
      checked: true,
      code: "",
      inputCode: "",
      isMobile:false,
      
    };
  },
  created() {
    //enter登录事件
    var that = this;
    this.isMobile=this._isMobile()
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        that.onSubmit();
      }
    };
    if (local.get("name").length > 0 && local.get("password").length > 0) {
      this.checked = true;
      this.form.name = local.get("name");
      this.form.pwd = local.get("password");
    }
  },
  mounted() {
    this.createCode();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    //   表单验证
    onSubmit() {
      this.$refs.form.validate(async (vali) => {
        if (vali) {
          if(this.inputCode == ''){
            this.$message.error('请输入验证码')
            return
          }
          if(this.inputCode.toLowerCase() != this.code.toLowerCase()){
            this.$message.error('验证码错误')
            this.inputCode = ''
            this.createCode()
            return
          }

          //通过验证后发送请求
          let { code, msg, data, token } = await login_api({
            nickName: this.form.name,
            passWord: this.form.pwd,
          });
          if (code == 0) {
            //储存token
            token = data.token;
            local.set("tk", token);
            //   console.log(data, "身份信息");
            local.set("releaseScheduling", data.id);
            local.set("role", data.roleKeyList);
            local.set("userName", data.nickName);
            local.set("powersIdsAndChild", data.powersIdsAndChild);
            local.set("telephone", data.telephone);

            if (this.checked == true) {
              local.set("name", this.form.name);
              local.set("password", this.form.pwd);
            }
            // this.$message({
            //   message: "登陆成功",
            //   type: "success",
            // });
            setTimeout(() => {
              this.$router.push("/workbench");
            }, 500);
          } else {
            this.$message({
              showClose: true,
              message: "登录失败，" + msg + "或账号密码错误",
              type: "error",
            });
          }
        } else {
          return;
        }
      });
    },
    //眼睛的开关
    display(e) {
      if (e.target.className.includes("icon-yanjing")) {
        this.isshow = !this.isshow;
      }
    },
    //保存用户名密码
    change() {
      if (this.checked == true) {
        local.set("name", this.form.name);
        local.set("password", this.form.pwd);
        this.form.name = local.get("name");
        this.form.pwd = local.get("password");
      } else {
        local.remove("name");
        local.remove("password");
      }
    },
    createCode() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      // 设置验证码长度，
      for (let i = 0; i < 4; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      this.code = text;
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background: url("../assets/imgs/bg@2x.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .user {
    width: 28%;
    height: 515px;
    position: absolute;
    top: 20%;
    right: 15%;
    display: flex;
    flex-direction: column;
    .head {
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      p {
        font-size: 26px;
        font-weight: 400;
        color: #256def;
      }
    }
    .el-form {
      // flex: 1;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-around;
      .el-form-item {
        position: relative;
        img {
          position: absolute;
          left: 0;
          top: 5px;
        }
        /deep/.el-input__inner {
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
          margin-top: 5px;
          padding: 0 10px 0 30px;
          text-indent: 2em;
          box-sizing: border-box;
          font-size: 16px;
          width: 100%;
          height: 40px;
          line-height: 40px;
        }
        /deep/.el-button {
          width: 100%;
          background-color: #256def;
          // padding: 15px 0;
          box-sizing: border-box;
          border-radius: 8px;
          font-weight: bold;
        }
        /deep/.el-button:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
//自动登录
/deep/.el-checkbox {
  color: #cccccc;
}
</style>