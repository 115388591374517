





import req from "../utils/req"

//获取上班状态
export function getManagerId_api(params) {
    return req.get("/otaHotel/workCheck/getManagerId", params)
}
//修改上班状态
export function setManagerStatus_api(params) {
    return req.get("/otaHotel/workCheck/upAndDown", params)
}
//修改密码
export function setPassWord_api(params) {
    return req.get("/otaHotel/manager/setPassWord", params)
}

//获取待下线酒店列表
export function TapeDownlineList1_api(params) {
    return req.get("/otaHotel/hotelSon/tapeDownlineList1", params)
}
//获取待下线酒店数量

export function tapeDownlineCount_api(params) {
    return req.get("/otaHotel/hotelSon/tapeDownlineCount", params)
}