<template>
  <div>
    <div class="head">
      <!-- 名称 logo -->
      <div class="name">
        <img src="../assets/imgs/logo.png" alt="加载失败" />
        <span>成都嘉瑜信息技术有限公司</span>
      </div>
      <!-- 上下班 权限 -->
      <div class="work">
        <el-button size="mini" type="primary" @click="changeStatusFn">{{
          statusValue
        }}</el-button>
        <div class="user">
          <el-menu
            :default-active="activeIndex2"
            class="el-menu-demo"
            mode="horizontal"
            menu-trigger="click"
            background-color="#0b1c35"
            text-color="#fff"
            active-text-color="#fff"
            @open="openMenu"
          >
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-s-custom" style="color: #fff"></i>
                <span>{{ userName }}</span>
              </template>
              <el-menu-item
                style="width: 80px"
                @click="openEditPswDia"
                index="2-2"
                >修改密码</el-menu-item
              >
              <el-menu-item style="width: 80px" @click="loginout" index="2-1"
                >退出登录</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
        <div class="headiconbox" @click="openMessageBox">
          <el-badge :is-dot='isDot' class="headiconboxvadge">
            <!-- <el-button
            size="mini"
            plain
            circle
            type="info"
            icon="el-icon-message-solid"
          ></el-button> -->
            <div class="headiconboxlabel">
              <i class="el-icon-message-solid"></i>
            </div>
          </el-badge>
        </div>
      </div>
    </div>

    <EditPswDia ref="editpswdia"></EditPswDia>
  </div>
</template>

<script>
// import moment from "moment";
import { getManagerId_api, setManagerStatus_api,tapeDownlineCount_api } from "../apis/head.js";
import EditPswDia from "./EditPswDia.vue";
//引入local
import local from "@/utils/local.js";
export default {
  components: {
    EditPswDia,
  },
  data() {
    return {
      statusValue: "上班打卡",
      activeIndex2: "1",
      userName: "",
      statusKey: "",
      isDot:false,
    };
  },
  created() {
    this.getManagerId();
    this.tapeDownlineCount()
    this.openMessageBox()
  },
  mounted() {
    this.userName = local.get("userName");
  },
  methods: {
    //上下班打卡
    change() {
      //   this.status = this.status == "上班打卡" ? "下班打卡" : "上班打卡";
      if (this.statusValue == "下班打卡") {
        this.$confirm("确认打卡下班?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.setManagerStatus();
          })
          .catch((action) => {
            this.$message({
              type: "info",
              message: action === "cancel" ? "取消打卡" : "停留在当前页面",
            });
          });
      } else if (this.statusValue == "上班打卡") {
      }
    },
    changeStatusFn() {
      if (this.statusValue == "下班打卡") {
        this.$confirm("确认打卡下班?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.setManagerStatus();
          })
          .catch((action) => {
            this.$message({
              type: "info",
              message: action === "cancel" ? "取消打卡" : "停留在当前页面",
            });
          });
      } else if (this.statusValue == "上班打卡") {
        this.setManagerStatus();
      }
    },
    //获取上班状态
    async getManagerId() {
      let { code, data } = await getManagerId_api();
      if (code == 0) {
        this.statusValue = data.value;
        this.statusKey = data.key;
        console.log(this.statusKey);
      }
    },
    //打卡
    async setManagerStatus() {
      let status;
      if (this.statusKey == "down") {
        status = "up";
      } else if (this.statusKey == "up") {
        status = "down";
      }
      let { code, data } = await setManagerStatus_api({
        upAndDownStatus: status,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        if (status == "up") {
          console.log("if1");
          this.statusKey = "up";
          this.statusValue = "下班打卡";
        } else if (status == "down") {
          console.log("if2");
          this.statusKey = "down";
          this.statusValue = "上班打卡";
        }

        console.log(this.statusValue);
      }
    },
    //退出登录
    loginout() {
      local.remove("tk");
      local.remove("releaseScheduling");
      local.remove("role");
      local.remove("userName");
      local.remove("powersIdsAndChild");
      local.remove("hotelId");

      //local.clear();
      this.$router.push("/login");
    },
    openEditPswDia() {
      this.$refs.editpswdia.open();
    },
    openMenu() {
      let element = document.querySelector(".el-menu--popup");
      element.style.minWidth = "117px";
    },
   async tapeDownlineCount(){
       let {code,data}= await tapeDownlineCount_api()
       if(code==0){
         if(data>0){
               this.isDot=true
         }
          
       }
    },
    openMessageBox(){
      this.$parent.getTapeDownlineList1()
    }
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
  position: relative;
  .name {
    display: flex;
    align-items: center;
    img {
      margin-right: 15px;
    }
  }
  .work {
    display: flex;
    align-items: center;
    .user {
      margin-left: 50px;
    }
  }
  .headiconbox {
    // position: absolute;
    // top: 10px;
    // right: -20px;

    color: #fff;

    .headiconboxlabel {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
    }
    .headiconboxvadge {
      font-size: 40px;
    }
  }
}
/deep/ .el-menu--popup {
  min-width: 118px !important;
}
/deep/ .el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 118px !important;
}
/deep/ .el-badge__content.is-dot {
  width: 4px;
  height: 4px;
}
</style>