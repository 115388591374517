<template>
  <div class="layout">
    <!-- 头部内容 -->
    <div class="header">
      <Head></Head>
    </div>
    <!-- 主体内容 -->
    <div class="main">
      <!-- 公共导航栏 -->
      <div class="nav">
        <Nav></Nav>
      </div>
      <div class="layoutmessagebox" v-if="isShow">
        <div class="layoutmessagetitle">
          <div>消息通知</div>
          <div class="closeiconbox" @click="colseIconBoxFn">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="layoutmainbox">
          <div class="layoutmainitem" v-for="item in tapeDownlineList1" :key="item.hotelId">
            <div class="layoutmainboxtitle">
              <div class="layoutmainboxtitleleft">
                {{ item.title }}
              </div>
              <div class="layoutmainboxtitleright">
                {{ item.onlineTime }}
              </div>
            </div>
            <div class="layoutmainboxtitleright margintop10">
              {{ item.applyReason }}
            </div>
            <div class="margintop10 textright">
              <el-button size="mini" plain type="info" @click="toHotelPage">查看详情</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 对应页面 -->
      <div class="page">
        <router-view class="container"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import Head from "../components/Head.vue";
import { TapeDownlineList1_api } from "../apis/head";
export default {
  components: { Nav, Head },
  data() {
    return {
      isShow: false,
      tapeDownlineList1: [],
    };
  },
  created(){
      // this.getTapeDownlineList1()
  },
  methods: {
    async getTapeDownlineList1() {
      let { code, data } = await TapeDownlineList1_api({
        page: 1,
        rows: 99999,
      });
      if (code == 0) {
        this.tapeDownlineList1 = data.records;
        if (this.tapeDownlineList1.length == 0) {
          this.$message.warning("暂无消息通知");
         // this.isShow = true;
        } else {
          this.isShow = true;
        }
      }
    },
    colseIconBoxFn(){
       this.isShow = false;
    },
    toHotelPage(){
      this.$router.push({
        path:'/hotel',
        query:{
          activeIndex:3
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  // height: 100%;
  min-height: 1400px;
  // min-height: calc(100% - 135px);
  display: flex;
  flex-direction: column;
  background: rgb(250, 252, 255);
  .header {
    width: 100%;
    min-height: 75px;
    background-color: #0b1c35;
  }
  .main {
    flex: 1;
    .nav {
      min-height: 60px;
      background: #102647;
    }
    .page {
      margin-top: 20px;
    }
  }
}
.container {
  width: 1200px;
  margin: 0 auto;
}
/deep/.el-menu.el-menu--horizontal {
  border: none;
}
/deep/ .el-dialog {
  border-radius: 20px;
}
.layoutmessagebox {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 300px;
  min-height: 500px;
  background: #ffffff;
  z-index: 999;
  box-shadow: 0px 0px 8px 4px rgba(220, 228, 240, 0.42);
}
.layoutmessagetitle {
  margin: 0 auto;
  width: 280px;
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.layoutmainbox {
  margin: 0 auto;
  width: 280px;
  margin-top: 10px;
}
.layoutmainboxtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.layoutmainboxtitleleft {
  font-size: 14px;
  color: #102647;
}
.layoutmainboxtitleright {
  font-size: 12px;
  color: #566980;
}
.margintop10 {
  margin-top: 10px;
}
.textright {
  text-align: right;
}
.layoutmainitem{
  border-bottom: 1px solid #999999;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.closeiconbox{
  cursor: pointer;
}
/deep/ .el-button--info.is-plain{
  color: #333333;
}
</style>